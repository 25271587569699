import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { differenceInYears, parse } from 'date-fns'

export const useBookingRequestInsuranceConditions = (
  {
    dateFrom,
    dateTo,
  }: {
    dateFrom?: MaybeRef<string | null>
    dateTo?: MaybeRef<string | null>
  } = {}) => {
  const { camper, vehicle } = storeToRefs(useCamperStore())
  const { user } = storeToRefs(useUserStore())

  const isInsuranceDrivingLicenceConditionFulfilled = computed(() => {
    if (!isDefined(vehicle.value?.driving_licence_required)) {
      return false
    }
    return vehicle.value.driving_licence_required === Constants.VEHICLES.DRIVING_LICENCE.B
  })

  const isInsuranceManagedByYescapa = computed(() => camper.value?.regular_insurance.slug !== Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG)

  const isInsuranceAgeConditionFulfilled = computed(() => {
    const dateFromValue = toValue(dateFrom)
    const dateToValue = toValue(dateTo)

    if (!isDefined(user.value?.borned_on)
      || !isDefined(camper.value)
      || !isDefined(dateFromValue)
      || !isDefined(dateToValue)) {
      return false
    }

    const insuranceMinDriverAge = camper.value.regular_insurance.condition?.min_drivers_age ?? camper.value.regular_insurance.min_driver_age
    const insuranceMaxDriverAge = camper.value.regular_insurance.condition?.max_drivers_age ?? camper.value.regular_insurance.max_driver_age

    const birthday = new Date(user.value.borned_on)
    const dateFromParsed = parse(dateFromValue, 'yyyy-MM-dd', new Date())
    const dateToParsed = parse(dateToValue, 'yyyy-MM-dd', new Date())
    const userAgeDeparture = differenceInYears(dateFromParsed, birthday)
    const userAgeArrival = differenceInYears(dateToParsed, birthday)

    return (insuranceMinDriverAge === null || userAgeDeparture >= insuranceMinDriverAge) && (insuranceMaxDriverAge === null || userAgeArrival <= insuranceMaxDriverAge)
  })

  return {
    isInsuranceManagedByYescapa,
    isInsuranceDrivingLicenceConditionFulfilled,
    isInsuranceAgeConditionFulfilled,
  }
}
